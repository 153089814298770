
import { Component, Vue, Watch } from "vue-property-decorator";
import { Mixins } from "vue-mixin-decorator";
import GeneralMixin from "@/mixin";
import { Action, Getter, Mutation } from "vuex-class";
import { IShipmentListRequest, IShipment } from "@/types/shipment";
import { IPagination, ISelectItem } from "@/types";
import { ICustomerListRequest } from "@/types/customer";
import { ICustomerProductListRequest } from "@/types/customer_product";
import { IProductListRequest } from "@/types/product";

@Component({})
export default class extends Mixins<GeneralMixin>(GeneralMixin) {
  // shipment
  @Action("shipment/userGetList")
  public getList!: (request: IShipmentListRequest) => Promise<boolean>;

  @Getter("shipment/list")
  public shipmentList!: IShipment[];

  @Getter("shipment/pagination")
  public pagination!: IPagination<IShipment[]>;

  // customer
  @Action("customer/userGetList")
  public getCustomerList!: (request: ICustomerListRequest) => Promise<boolean>;

  @Getter("customer/selectItem")
  public customerList!: ISelectItem[];

  @Mutation("customer/clear")
  public customerClear!: () => void;

  @Mutation("shipment/clear")
  public clear!: () => void;

  // customer_product
  @Action("customer_product/userGetList")
  public getCustomerProductList!: (
    request: ICustomerProductListRequest
  ) => Promise<boolean>;

  @Getter("customer_product/selectItem")
  public customerProductList!: ISelectItem[];

  @Mutation("customer_product/clear")
  public customerProductClear!: () => void;

  // product
  @Action("product/userGetList")
  public getProductList!: (request: IProductListRequest) => Promise<boolean>;

  @Getter("product/selectItem")
  public productList!: ISelectItem[];

  @Mutation("product/clear")
  public productClear!: () => void;

  // pdf
  @Action("pdf/userShipmentSchedulePDF")
  public getPDF!: (request: IShipmentListRequest) => Promise<boolean>;

  // watch
  @Watch("$route.query")
  public async changeQuery() {
    this.queryToParams();
    this.changeShipmentCheckList();
    await this.getList(this.params);
  }

  @Watch("shipment_check_list")
  public changeShipmentCheckList() {
    this.params.product_shipments = this.shipment_check_list.map(item => {
      return {
        id: item["id"],
        shipment_id: item["shipment_list_id"]
      };
    });
  }

  // パラメータ定義
  public params: IShipmentListRequest = {
    page: 1,
    search: "",
    search_span_start: "",
    search_span_end: "",
    user: 0,
    supplier: 0,
    multiple_shipment_status: [],
    customer: 0,
    customer_pn: 0,
    product: 0,
    sort_pattern: "",
    sort_date_column: "",
    sort_direction: "",
    is_memo: false,
    is_shipment_no: "",
    is_invoice_no: "",
    product_shipments: []
  };

  //
  // variables
  //
  public shipment_status = Vue.prototype.$shipmentStatus;
  public shipment_check_list = [];
  public add_span_end = false;
  public span_end_show = true;
  public span_day_show = false;
  public menu = {
    shipment_date: false,
    start: false,
    end: false,
    day: false
  };

  public status_items = [
    { value: this.shipment_status.pre_shipment, text: "事前出荷" },
    { value: this.shipment_status.estimated_shipment, text: "出荷予定" },
    { value: this.shipment_status.shipped, text: "出荷済み" }
  ];

  public sort_items = [
    { value: "", text: "選択なし" },
    { value: "customer_kana", text: "顧客名順" },
    { value: "order_no", text: "顧客注文番号順" }
  ];

  public sort_date_items = [
    { value: "", text: "選択なし" },
    { value: "asc", text: "昇順" },
    { value: "desc", text: "降順" }
  ];

  public sort_date_list = [
    { value: "", text: "選択なし" },
    { value: "shipment_date", text: "出荷日" },
    { value: "fixed_delivery_date", text: "納品日" }
  ];

  public sort_shipment_no_list = [
    { value: "", text: "選択なし" },
    { value: "set", text: "納品Noあり" },
    { value: "no_set", text: "納品Noなし" }
  ];

  public sort_invoice_no_list = [
    { value: "", text: "選択なし" },
    { value: "set", text: "請求Noあり" },
    { value: "no_set", text: "請求Noなし" }
  ];

  public headers = [
    {
      text: "顧客名 / 支店名",
      value: "customer_and_branch_name",
      sortable: false
    },
    { text: "顧客注文番号", value: "received_order_no", sortable: false },
    { text: "商品名", value: "product_name", sortable: false },
    { text: "品番", value: "product_code", sortable: false },
    { text: "顧客品番", value: "customer_pn", sortable: false },
    { text: "ロット(数量)", value: "lot_no_quantity", sortable: false },
    {
      text: "受注数量",
      value: "received_order_quantity",
      sortable: false,
      align: "end"
    },
    {
      text: "出荷日",
      value: "shipment_date",
      sortable: false
    },
    {
      text: "納品日",
      value: "fixed_delivery_date",
      sortable: false
    },
    { text: "備考", value: "memo", sortable: false },
    { text: "納品No", value: "shipment_no", sortable: false },
    { text: "請求No", value: "invoice_no", sortable: false },
    {
      text: "出荷状況",
      value: "shipment_status",
      sortable: false
    }
  ];

  //
  // methods
  //
  private async created() {
    this.clear();
    this.customerClear();
    this.customerProductClear();
    this.productClear();
    this.queryToParams();

    await Promise.all([
      this.getList(this.params),
      this.getCustomerList({ per_page: 0 }),
      this.getCustomerProductList({ per_page: 0 }),
      this.getProductList({ per_page: 0 })
    ]);
  }

  // urlからパラメータをセットする
  private queryToParams() {
    const query = this.$route.query;
    this.params = {
      page: query.page ? Number(query.page) : 1,
      user: query.user ? Number(query.user) : 0,
      supplier: query.supplier ? Number(query.supplier) : 0,
      customer: query.customer ? Number(query.customer) : 0,
      customer_pn: query.customer_pn ? Number(query.customer_pn) : 0,
      product: query.product ? Number(query.product) : 0,
      search: query.search ? decodeURI(query.search as string) : "",
      search_span_start: query.search_span_start
        ? decodeURI(query.search_span_start as string)
        : "",
      search_span_end: query.search_span_end
        ? decodeURI(query.search_span_end as string)
        : "",
      sort_date_column: query.sort_date_column
        ? decodeURI(query.sort_date_column as string)
        : "",
      sort_pattern: query.sort_pattern
        ? decodeURI(query.sort_pattern as string)
        : "",
      sort_direction: query.sort_direction
        ? decodeURI(query.sort_direction as string)
        : "",
      is_memo: query.is_memo ? Boolean(query.is_memo) : false,
      is_shipment_no: query.is_shipment_no
        ? decodeURI(query.is_shipment_no as string)
        : "",
      is_invoice_no: query.is_invoice_no
        ? decodeURI(query.is_invoice_no as string)
        : "",
      multiple_shipment_status: this.$route.query.multiple_shipment_status
        ? Array.isArray(this.$route.query.multiple_shipment_status)
          ? this.$route.query.multiple_shipment_status.map(Number)
          : this.$route.query.multiple_shipment_status.split(",").map(Number)
        : []
    };
  }

  // 1ページ目から検索
  // 主に検索条件が変更されたときに利用
  public async searchFirst() {
    this.params.page = 1;
    if (this.params.sort_date_column == "") {
      this.params.sort_direction = "";
    }
    await this.paramsToQuery();
  }

  // URLのパラメータをセットする
  private paramsToQuery() {
    this.$router
      .push({
        path: this.$router.currentRoute.path,
        query: {
          search: this.params.search,
          search_span_start: this.params.search_span_start,
          search_span_end: this.params.search_span_end,
          sort_date_column: this.params.sort_date_column,
          sort_pattern: this.params.sort_pattern,
          sort_direction: this.params.sort_direction,
          page: this.params.page ? String(this.params.page) : "1",
          supplier: this.params.supplier ? String(this.params.supplier) : "0",
          user: this.params.user ? String(this.params.user) : "0",
          customer: this.params.customer ? String(this.params.customer) : "0",
          customer_pn: this.params.customer_pn
            ? String(this.params.customer_pn)
            : "0",
          product: this.params.product ? String(this.params.product) : "0",
          is_memo: this.params.is_memo ? String(this.params.is_memo) : "",
          is_shipment_no: this.params.is_shipment_no
            ? String(this.params.is_shipment_no)
            : "",
          is_invoice_no: this.params.is_invoice_no
            ? String(this.params.is_invoice_no)
            : "",
          multiple_shipment_status: this.params.multiple_shipment_status
            ? this.params.multiple_shipment_status.map(String)
            : []
        }
      })
      .catch(() => {});
  }

  get setValue() {
    return this.shipmentList
      .map(shipment =>
        shipment.product_shipment.map(product => ({
          id: product.id,
          shipment_list_id: shipment.id,
          customer_short_name: shipment.customer_short_name,
          branch_short_name: shipment.branch_short_name,
          received_order_no: shipment.received_order_no,
          product_name: product.product_name,
          product_code: product.product_code,
          customer_pn: product.customer_pn,
          lot_no_quantity:
            (product.lot_no || "ー ") +
            " (" +
            Number(product.quantity).toLocaleString() +
            ")",
          received_order_quantity: Number(
            product.received_order_quantity
          ).toLocaleString(),
          shipment_date: shipment.shipment_date,
          fixed_delivery_date: shipment.fixed_delivery_date,
          memo: product.memo,
          shipment_no: shipment.shipment_no,
          invoice_no: shipment.invoice_no,
          shipment_status: shipment.shipment_status
        }))
      )
      .reduce(function (item, val) {
        return item.concat(val);
      }, []);
  }

  //行をクリックした際の挙動
  public rowClick(shipment: IShipment) {
    this.$router.push(`/user/shipment/${shipment.shipment_list_id}`);
  }

  // 開始日、終了日、日付指定のリセット
  public clearSearchStart() {
    this.params.search_span_start = "";
    this.searchFirst();
  }

  public clearSearchEnd() {
    this.params.search_span_end = "";
    this.searchFirst();
  }

  public clearSearchSpanDay() {
    this.params.search_span_start = "";
    this.params.search_span_end = "";
    this.searchFirst();
  }

  // 日付指定チェックボックスをクリックした際の挙動
  public spanToggle() {
    if (this.add_span_end == false) {
      this.params.search_span_end = "";
      this.params.search_span_start = "";
      this.span_end_show = true;
      this.span_day_show = false;
      this.searchFirst();
    } else {
      this.params.search_span_end = "";
      this.params.search_span_start = "";
      this.span_end_show = false;
      this.span_day_show = true;
      this.params.search_span_end = this.params.search_span_start;
      this.searchFirst();
    }
  }

  // 日付指定した際の挙動
  public async searchFirstSpanDay() {
    this.params.page = 1;
    this.params.search_span_end = this.params.search_span_start;
    await this.paramsToQuery();
  }

  // 期間選択時のバリデーション
  public spanEndRule() {
    if (
      !this.params.search_span_end ||
      !this.params.search_span_start ||
      this.params.search_span_end >= this.params.search_span_start
    ) {
      return true;
    }

    return "正しい日付を入力してください";
  }

  //出荷スケジュール出力
  public shipmentSchedule() {
    this.getPDF(this.params);
  }
}
